import type { Currency } from '@aerapass/country-data';
import { currencies } from '@aerapass/country-data';
import { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import type { TpFiatCurrency } from './types';

export const fiatCurrencies = Object.values(FiatCurrencyCode).reduce(
  (acc, code) => {
    const fiatCurrency = currencies[code] as Currency | undefined;

    if (!fiatCurrency) {
      return acc;
    }

    // safe to cast as we know the code is a FiatCurrencyCode
    acc[fiatCurrency.code as FiatCurrencyCode] = {
      code: fiatCurrency.code as FiatCurrencyCode,
      decimals: fiatCurrency.decimals,
      label: fiatCurrency.name,
      symbol: fiatCurrency.symbol,
    };
    return acc;
  },
  {} as Record<FiatCurrencyCode, TpFiatCurrency>,
);

export const currencyToPrimaryCountry: Record<FiatCurrencyCode, string> = {
  AED: '',
  ARS: 'AR',
  AUD: '',
  BDT: '',
  BHD: '',
  BMD: '',
  BRL: 'BR',
  CAD: '',
  CHF: '',
  CLP: '',
  CNY: '',
  COP: '',
  CZK: '',
  DKK: '',
  ETB: '',
  EUR: 'EU',
  GBP: 'GB',
  GHS: 'GH',
  HKD: '',
  HUF: '',
  IDR: '',
  ILS: '',
  INR: '',
  JPY: '',
  KRW: '',
  KWD: '',
  LKR: '',
  MMK: '',
  MXN: 'MX',
  MYR: '',
  NGN: 'NG',
  NOK: '',
  NZD: '',
  PHP: '',
  PKR: '',
  PLN: '',
  RUB: '',
  SAR: '',
  SEK: '',
  SGD: '',
  THB: '',
  TRY: '',
  TWD: '',
  UAH: '',
  UGX: '',
  USD: 'US',
  VEF: '',
  VND: '',
  XAF: '',
  ZAR: '',
};
